<template>
    <div class="page-content page-editor">
        <div class="w-full" v-if="loading">
            <Skeleton shape="rectangle" width="100%" height="1.4rem" class="mb-3" v-for="n in 8" :key="n" />
        </div>
        <template v-if="!loading && page">
            <div class="grid">
                <template v-for="(col, i1) in page.content.layout[0].children" :key="i1">
                    <div :class="col.class">
                        <Button class="p-button-text p-button-sm absolute top-0 right-0 z-5" v-tooltip.bottom="{ value: 'Opciones columna' }" @click="showColumnMenu(page.content.layout[0].children, i1, $event)">
                            <iconify-icon icon="mdi:cog-box" style="font-size: 1.4em;" />
                        </Button>
                        <template v-for="(el1, i2) in col.children" :key="i2">
                            <div v-if="el1.type == 'header-3'" class="header-element flex flex-row align-items-center justify-content-center" @click="showTextMenu(col.children, i2, $event)">
                                <h3 :class="el1.class">{{ el1.title }}</h3>
                                <iconify-icon icon="mdi:pencil" class="mb-5 ml-2" style="font-size: 1.6em;" />
                            </div>
                            <div v-else-if="el1.type == 'grid'" :class="el1.class">
                                <div v-for="(el2, i3) in el1.children" :key="i3" :class="el2.class">
                                    <Button class="p-button-text p-button-sm absolute top-0 right-0 z-5" v-tooltip.bottom="{ value: 'Opciones columna' }" @click="showColumnMenu(el1.children, i3, $event)">
                                        <iconify-icon icon="mdi:cog-box" style="font-size: 1.4em;" />
                                    </Button>
                                    <template v-for="(el3, i4) in el2.children" :key="i4">
                                        <div v-if="el3.type == 'header-3'" class="header-element flex flex-row align-items-center justify-content-center" @click="showTextMenu(el2.children, i4, $event)">
                                            <h3 :class="el3.class">{{ el3.title }}</h3>
                                            <iconify-icon icon="mdi:pencil" class="mb-5 ml-2" style="font-size: 1.6em;" />
                                        </div>
                                        <div v-else-if="el3.type == 'category-button'" :class="el3.class">
                                            Botón de categoría
                                        </div>
                                        <div v-else-if="el3.type == 'page-button'" :class="el3.class">
                                            Botón de página
                                        </div>
                                    </template>
                                </div>
                            </div>
                            <div v-else-if="el1.type == 'category-feed'" :class="el1.class">
                                Feed de categoría
                            </div>
                        </template>
                    </div>
                </template>
            </div>
        </template>
    </div>
    <Menu ref="colOptionsMenu" :model="colMenu" :popup="true">
        <template #item="{item}">
            <div class="p-menuitem-content">
                <a class="p-menuitem-link" @click="item.command">
                    <iconify-icon :icon="item.icon" style="height: 1em; margin-right: .25em;"></iconify-icon>
                    <span>{{ item.label }}</span>
                </a>
            </div>
        </template>
    </Menu>
    <Menu ref="textOptionsMenu" :model="textMenu" :popup="true">
        <template #item="{item}">
            <div class="p-menuitem-content">
                <a class="p-menuitem-link" @click="item.command">
                    <iconify-icon :icon="item.icon" style="height: 1em; margin-right: .25em;"></iconify-icon>
                    <span>{{ item.label }}</span>
                </a>
            </div>
        </template>
    </Menu>
    <Dialog v-model:visible="modifyColumnDialog" :style="{width: '450px'}" header="Cambiar columnas" :modal="true" class="p-fluid">
        <div class="confirmation-content">
            <div class="field mb-4">
                <label>Tamaño de columna (cuadrícula de 12 columnas)</label>
                <Dropdown v-model="selectedColumnSize" :options="columnOptions" class="w-full mb-2" />
            </div>
        </div>
        <template #footer>
            <Button label="Cancelar" icon="pi pi-times" :loading="working" class="p-button-text" @click="modifyColumnDialog = false" :disabled="working" />
            <Button label="Aceptar" icon="pi pi-check" :loading="working" class="p-button-text p-button-success" @click="changeColumnSize" :disabled="working" />
        </template>
    </Dialog>
    <Dialog v-model:visible="modifyTextDialog" :style="{width: '450px'}" header="Cambiar texto" :modal="true" class="p-fluid">
        <div class="confirmation-content">
            <div class="field mb-4">
                <label>Texto</label>
                <InputText v-model="textValue" />
            </div>
        </div>
        <template #footer>
            <Button label="Cancelar" icon="pi pi-times" :loading="working" class="p-button-text" @click="modifyTextDialog = false" :disabled="working" />
            <Button label="Aceptar" icon="pi pi-check" :loading="working" class="p-button-text p-button-success" @click="changeText" :disabled="working || !textValue || textValue.length < 2" />
        </template>
    </Dialog>
</template>

<script>
import RouteGuardService from '@/service/RouteGuardService.js';
import PageService from '@/service/PageService.js';

const compUrl = '/pages';

export default {
    beforeRouteEnter(to) {
        let goTo = RouteGuardService.isLoggedIn();

        if (goTo !== true) {
            window.sessionStorage.setItem('afterLogin', `${compUrl}/${to.params.slug}/editor`);
        }

        return goTo;
    },
    data() {
        return {
            page: null,
            loading: true,
            working: false,
            modifyColumnDialog: false,
            modifyTextDialog: false,
            colMenu: [
                {
                    label: 'Modificar',
                    icon: 'mdi:pencil',
                    command: () => {
                        this.modifyColumn();
                    }
                },
                {
                    label: 'Eliminar',
                    icon: 'mdi:delete-forever',
                    command: () => {
                        this.deleteElement();
                    }
                },
                {
                    label: 'Insertar columna antes',
                    icon: 'mdi:table-column-plus-before',
                    command: () => {
                        this.inserColumnBefore();
                    }
                },
                {
                    label: 'Insertar columna después',
                    icon: 'mdi:table-column-plus-after',
                    command: () => {
                        this.inserColumnAfter();
                    }
                },
            ],
            textMenu: [
                {
                    label: 'Modificar',
                    icon: 'mdi:pencil',
                    command: () => {
                        this.modifyText();
                    }
                },
                {
                    label: 'Eliminar',
                    icon: 'mdi:delete-forever',
                    command: () => {
                        this.deleteElement();
                    }
                },
            ],
            columnOptions: [
                1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12
            ],
            selectedItemArray: null,
            selectedItemIndex: 0,
            selectedColumn: null,
            selectedColumnSize: 1,
            selectedTextElement: null,
            textValue: null
        }
    },
    pageService: null,
    pageContent: null,
    pageSlug: null,
    emits: ['updateBreadcrumbs'],
    created() {
        this.pageService = new PageService();

        if (!this.$route.params.slug) {
            this.$watch(
                () => this.$route.params,
                (params) => {
                    this.pageSlug = params.slug;
                },
            )
        } else {
            this.pageSlug = this.$route.params.slug;
        }
    },
    mounted() {
        this.pageContent = document.querySelector('.page-content');

        if (!this.$appState.estate) {
            this.$watch(
                () => this.$appState.estate,
                () => {
                    this.fetchPage();
                },
            );
        } else {
            this.fetchPage();
        }
    },
    methods: {
        fetchPage() {
            this.loading = true;

            this.pageService.fetchPageContent(this.$appState.estate.id, this.pageSlug).then(response => {
                this.page = response.data.page;

                if (this.page.content.page_type === 'basic') {
                    this.$router.replace(RouteGuardService.loginUrl);
                }

                this.loading = false;
                this.$emit('updateBreadcrumbs', {label: 'Edición de página', icon: 'mdi:pencil', to: `${compUrl}/${this.pageSlug}/edit`, replace: false});
            }).catch(error => {
                console.error(error);
                this.loading = false;
                this.$toast.add({severity:'error', summary: 'Error', detail: 'No se ha podido obtener la página', life: 3000});

                if (error.response && error.response.status === 401) {
                    RouteGuardService.logout();
                    window.sessionStorage.setItem('afterLogin', '/');
                    this.$router.replace(RouteGuardService.loginUrl);
                }
            });
        },
        showColumnMenu(arr, i, ev) {
            this.selectedItemArray = arr;
            this.selectedItemIndex = i;
            this.selectedColumn = arr[i];
            this.$refs.colOptionsMenu.toggle(ev);
        },
        showTextMenu(arr, i, ev) {
            this.selectedItemArray = arr;
            this.selectedItemIndex = i;
            this.selectedTextElement = arr[i];
            this.$refs.textOptionsMenu.toggle(ev);
        },
        modifyColumn() {
            let col = '4';

            if (this.selectedColumn.class.includes('lg:')) {
                col = this.selectedColumn.class.split('lg:')[1];
            } else if (this.selectedColumn.class.includes('md:')) {
                col = this.selectedColumn.class.split('md:')[1];
            }

            this.selectedColumnSize = parseInt(col.split('-')[1]);

            this.modifyColumnDialog = true;
        },
        changeColumnSize() {
            this.selectedColumn.class = 'column col-12 md:col-6 lg:col-' + this.selectedColumnSize;
            this.modifyColumnDialog = false;
        },
        modifyText() {
            this.textValue = this.selectedTextElement.title;
            this.modifyTextDialog = true;
        },
        changeText() {
            this.selectedTextElement.title = this.textValue;
            this.modifyTextDialog = false;
        },
        deleteElement() {
            this.selectedItemArray = this.selectedItemArray.splice(this.selectedItemIndex, 1);
            this.$refs.colOptionsMenu.hide();
        },
        inserColumnBefore() {
            this.selectedItemArray = this.selectedItemArray.splice(this.selectedItemIndex, 0, {
                type: 'column',
                class: 'column col-12 md:col-6 lg:col-1'
            });
            this.$refs.colOptionsMenu.hide();
        },
        inserColumnAfter() {
            if ((this.selectedItemIndex + 1) === this.selectedItemArray.length) {
                this.selectedItemArray.push({
                    type: 'column',
                    class: 'column col-12 md:col-6 lg:col-1'
                });
            } else {
                this.selectedItemArray = this.selectedItemArray.splice(this.selectedItemIndex + 1, 0, {
                    type: 'column',
                    class: 'column col-12 md:col-6 lg:col-1'
                });
            }
            this.$refs.colOptionsMenu.hide();
        },
    },
}
</script>
<style scoped>
</style>